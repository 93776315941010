<template>
  <div class="hospital-manage-content">
    <el-scrollbar style="height: 100%">
      <part-title title="医院管理"></part-title>
      <!-- <el-button v-if="addHospital" class="content-btn" size="small" type="primary" @click="handleAddHospital">
        新增医院
      </el-button> -->
      <table-comp
          :table-opts="tableOpts"
          :data="tableData"
          :page="currentPage"
          :total="totalPage"
          :page-size="pageSize"
          :show-number="true"
          :open-handle="true"
          :handle-opts="handleOpts"
          handleWidth="220px"
          @other_1="handleEdit"
          @other_2="handleDoctorManage"
          @other_3="handleDownloadQRCode"
          @handleSizeChange="sizeChange"
          @handleCurrentChange="pageChange"
        >
        </table-comp>
    </el-scrollbar>
    <el-drawer
      :title="`${isEditDrawer ? '编辑': '新增'}医院信息`"
      :visible.sync="isVisibleAddHospitalDrawer"
      :wrapperClosable="false"
      :close-on-press-escape="false">
      <div style="margin: 24px">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          label-position="left">
          <el-form-item label="医院名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="医院邀请码" prop="inviteCode">
            <el-input v-model="ruleForm.inviteCode" maxlength="4"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button class="footer-btn" @click="isVisibleAddHospitalDrawer = false">取消</el-button>
          <el-button class="footer-btn" type="primary" @click="handelAdd">{{`${isEditDrawer ? '保存': '新增'}`}}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import PartTitle from '@/components/Part'
import TableComp from '@/components/Table/Table'
import { getHospitalList, createHospital, getHospitalInfo, updateHospitalInfo, getHospitalQrcode } from '@/service/module/hospitalManage'
import { dataToFile } from '@/utils/file'
export default {
  components: {
    PartTitle,
    TableComp
  },
  data () {
    return {
      tableOpts: [ // 列表表头设置
        {
          label: '医院名称',
          prop: 'name',
          width: '228px',
          render: (h, params) => {
            const name = params.row.name
            const nameText = '-'
            if (!name) {
              return h('span', {}, nameText)
            } else {
              return h('span', {}, name)
            }
          }
        },
        {
          label: '医院邀请码',
          prop: 'inviteCode',
          width: '128px',
          render: (h, params) => {
            const inviteCode = params.row.inviteCode
            const inviteCodeText = '-'
            if (!inviteCode) {
              return h('span', {}, inviteCodeText)
            } else {
              return h('span', {}, inviteCode)
            }
          }
        },
        {
          label: '创建人',
          prop: 'creatorName',
          // width: '128px',
          render: (h, params) => {
            const creater = params.row.creatorName
            const createrText = '-'
            if (!creater) {
              return h('span', {}, createrText)
            } else {
              return h('span', {}, creater)
            }
          }
        },
        {
          label: '创建时间',
          prop: 'createAt',
          width: '200px',
          render: (h, params) => {
            var moment = require('moment')
            const createAt = moment.unix(params.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')

            const createAtText = '-'
            if (!params.row.createAt) {
              return h('span', {}, createAtText)
            } else {
              return h('span', {}, createAt)
            }
          }
        }
      ],
      tableData: [],
      handleOpts: {
      },
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20, // 每页条数

      isVisibleAddHospitalDrawer: false,
      ruleForm: {},
      rules: {
        name: [
          { required: true, message: '请输入医院名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'change' },
          { pattern: /^[\u4E00-\u9FA5]+$/, message: '请输入中文' }
        ],
        inviteCode: [
          { required: true, message: '请输入医院邀请码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度在 4 个字符', trigger: 'change' },
          { pattern: /^\d+$/, message: '请输入数字' }
        ]
      },
      isEditDrawer: false,

      addHospital: false,
      changeHospitalStatusHandle: false
    }
  },
  created () {
    this.getHospitalList()
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'hospital-mgr:add-hospital') {
        this.addHospital = true
      }
      if (i === 'hospital-mgr:edit-hospital') {
        this.handleOpts.other_1 = {
          type: 'text',
          text: '编辑'
        }
      }
      if (i === 'hospital-mgr:manage-doctor') {
        this.handleOpts.other_2 = {
          type: 'text',
          text: '医生管理'
        }
      }
      if (i === 'hospital-mgr:download-qr-code') {
        this.handleOpts.other_3 = {
          type: 'text',
          text: '下载二维码'
        }
      }
      // else if (i === 'hospital-mgr:change-hospital-status') {
      //   this.changeHospitalStatusHandle = true
      //   this.tableOpts.push({
      //     label: '医院状态',
      //     prop: 'isEnable',
      //     type: 'switch',
      //     active_value: 1,
      //     inactive_value: 0,
      //     showHover: true,

      //     // width: '88px',
      //     handler: (data) => {
      //       this.tableData[data].isEnable === 0 ? this.tableData[data].isEnable = 1 : this.tableData[data].isEnable = 0
      //       disableHospitalStatus(
      //         {
      //           id: this.tableData[data].id,
      //           type: this.tableData[data].isEnable === 1 ? 'disable' : 'enable'
      //         }
      //       ).then(res => {
      //         if (res.code === 200) {
      //           this.$message.success(`医院${this.tableData[data].isEnable === 1 ? '停用' : '启用'}成功`)
      //           this.getHospitalList()
      //         } else {
      //           this.$message.error(res.message)
      //         }
      //       })
      //     },
      //     handlercontent: (data) => {
      //       return this.tableData[data].isEnable === 0 ? '关闭' : '启用'
      //     }
      //   })
      // }
    })
  },
  methods: {
    getHospitalList () {
      getHospitalList().then(res => {
        if (res && res.code === 200) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
          this.currentPage = res.data.currentPage
          this.pageSize = res.data.pageSize
        } else {
          this.$message.error(res.message)
        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getDoctorList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getDoctorList(val, this.pageSize)
    },
    handleEdit (i) {
      this.isEditDrawer = true
      this.isVisibleAddHospitalDrawer = true
      getHospitalInfo(i.id).then(res => {
        if (res) {
          this.ruleForm = res.data
        }
      })
    },
    handleDoctorManage (i) {
      console.log('点击了医生管理', i)
      this.$router.push({
        name: 'addDoctor',
        query: {
          name: i.name,
          id: i.id
        }
      })
    },
    handleDownloadQRCode (i) {
      console.log('点击了下载二维码', i)
      getHospitalQrcode(i.id).then(res => {
        if (res) {
          console.log(res)
          dataToFile(res)
        }
      })
    },
    handleAddHospital () {
      this.isVisibleAddHospitalDrawer = true
      this.isEditDrawer = false
      this.ruleForm = {}
    },
    handelAdd () {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.isEditDrawer) {
            updateHospitalInfo(this.ruleForm).then(res => {
              if (res.code === 200) {
                this.$message.success('保存成功！')
                this.isVisibleAddHospitalDrawer = false
                this.getHospitalList()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            createHospital(this.ruleForm).then(res => {
              if (res.code === 200) {
                this.$message.success('保存成功！')
                this.isVisibleAddHospitalDrawer = false
                this.getHospitalList()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.hospital-manage-content {
  margin: 0 20px;
  .content-btn {
    float: right;
    margin-bottom: 12px;
    font-size: 14px;
  }
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  right: 20px;
}
::v-deep .el-button--text {
  font-size: 12px;
  font-weight: 400;
}
</style>
